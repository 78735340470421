import { PQA_BACKEND_API } from 'App/Config'
import Swal from 'sweetalert2'

export const ValidateZip = async (code) => {
  try {
    const response = await fetch(`${PQA_BACKEND_API}/api/validate/zipcode`, {
      method: `POST`,
      headers: {
        Accept: `application/json, text/plain, */*`,
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        zipCode: code,
      }),
    }).then((res) => res.json())
    return response
  } catch (err) {
    Swal.fire({
      icon: `error`,
      title: `Oops...`,
      text: `Something went wrong!`,
    })
  }
}
