import tw from 'tailwind-styled-components'

export const Container = tw.div`
${({ display }) => display && `flex p-4`}
${({ tabscontainer }) => !tabscontainer && `my-3 `}
${({ shadow }) => !shadow && `shadow`}
    
    bg-form-gray
    mx-2 
    rounded
    
`
export const MenuButton = tw.div`
    relative 
    w-8 h-8
    flex 
    justify-center  
    items-center 
    rounded-full  
    text-gray-500 
    hover:text-gray-800 
    hover:bg-gray-300
`
export const Input = tw.input`
    ${({ errormessage }) => errormessage && `ring-red-500 `}
    ${({ disabled }) =>
      disabled ? `bg-gray-100 text-gray-400` : `text-gray-700 `}
    rounded-md
    border-transparent
    flex-1 
    border
    border-gray-300 
    w-full 
    mt-1
    py-1.5 
    px-4 
    bg-white 
    
    placeholder-gray-400 
    shadow-sm 
    text-base
    ring-red-500
    
    focus:outline-none 
    focus:ring-2 
    focus:ring-red-400 
    
    
`
export const Image = tw.img`
${({ overview }) =>
  overview
    ? `bg-white 
    object-cover 
    rounded-lg 
    h-42 
    w-64 `
    : `bg-gray-100
    border-1
    object-cover 
    rounded-full 
    cursor-pointer
    h-40 
    w-40
    `}
`
export const Label = tw.label`
    flex 
    flex-col 
    items-center 
    px-2 
    pt-1
`
export const StyledButton = tw.div`
    bg-red-600 
    float-right 
    w-72
    text-center
    py-2
    
    rounded-lg 
    transition 
    duration-500 
    ease-in-out 
    hover:bg-red-600 
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    cursor-pointer
    text-white
`

export const ButtonSecondary = tw.div`
    bg-gray-300 
    w-72
    text-center
    mx-1
    py-2 
    rounded-lg 
    transition 
    duration-500 
    ease-in-out 
    hover:bg-gray-400 
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    cursor-pointer
`
export const ButtonTransparent = tw.div`
    border
    border-red-300
    w-72
    text-center
    py-2 
    rounded-lg 
    transition 
    duration-500 
    ease-in-out 
    hover:border-red-400 
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    cursor-pointer
`
export const ResendButton = tw.div`
    bg-form-gray
    
    border-2
    border-custom-red
    text-center
    text-sm
    text-custom-red
    py-2 
    px-2 
    rounded-lg 
    transition 
    duration-500 
    ease-in-out 
    hover:bg-custom-red
    hover:text-white 
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    cursor-pointer
`
export const SubmitButton = tw.button`
${({ disabled }) =>
  disabled
    ? `bg-gray-300 text-gray-600 hover:bg-red-200  cursor-not-allowed`
    : `custom-button`}
    py-2 
    px-2 
    flex 
    justify-center 
    items-center 
    w-full 
    truncate
    
    focus:ring-red-400 
    focus:ring-offset-red-200 
    text-white  
    transition 
    ease-in 
    duration-200 
    text-center 
    text-base 
    font-semibold 
    shadow-md 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2  
    rounded-lg 
`

export const PageTitle = tw.div`
    text-2xl
    font-medium
    py-4
    px-4
    bg-red-600
    rounded-t-md
    text-white
`
