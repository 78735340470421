import { combineReducers } from 'redux'

import { customer } from './slices/customer'
import { custom } from './slices/custom'
import { driver } from './slices/driver'
import { vehicle } from './slices/vehicle'
import { global } from './slices/Global'

const rootReducer = combineReducers({
  customer,
  driver,
  vehicle,
  custom,
  global,
})

export { rootReducer }
