/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import {
  setActiveCustomerTab,
  setActiveCustomTab,
  setCurrentCustom,
  setLeadId,
  setQuoteResult,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { appendData } from 'App/Services/Append'
import { contactCard, Name } from 'App/Assets'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { useTranslation } from 'react-i18next'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import moment from 'moment'
import { MaskField } from 'App/Components/Common/MaskField'
import { requestQuote } from 'App/Services/RequestQoute'
import { useHistory } from 'react-router-dom'
import { PQA_BACKEND_API } from 'App/Config'

export const PersonalInfo = ({ activeTab }) => {
  const { customer } = useSelector(({ custom }) => custom)
  const {
    leadId,
    prospectType,
    customer: currentCustomer,
  } = useSelector(({ customer }) => customer)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [quoteData, setQuoteData] = useState(``)

  const [errors, setErrors] = useState({
    firstNameError: ``,
    lastNameError: ``,
    dobError: ``,
    phoneError: ``,
    formSubmit: false,
  })

  const dispatch = useDispatch()
  const history = useHistory()

  const validateForm = () => {
    dispatch(setActiveCustomerTab(activeTab + 1))
    if (!customer?.firstName?.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!customer?.lastName?.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!customer?.dob) {
      handleErrors(`dobError`, `Enter DOB`)
    } else {
      handleErrors(`dobError`, ``)
    }

    if (!customer?.phone) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        dob: customer?.dob,
      },
      leadId,
    )
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setActiveCustomTab(activeTab + 1))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  const prepayload = () => {
    let data = {
      quoteData: {
        customer: {
          doYouSmoke: customer.doYouSmoke,
          customerData: {
            firstName: customer?.firstName,
            lastName: customer?.lastName,
            phone: customer?.phone,
            dob: customer.dob,
          },
        },
        quoteSource: `WEB`,
        quoteType: prospectType,
        ip: currentCustomer?.ip,
        prospectId: leadId,
      },
    }

    if (customer?.email.length) {
      data.quoteData.customer.customerData.email = customer?.email
    }

    return data
  }

  const handlePayload = async () => {
    setLoading(true)
    const response = await requestQuote(prepayload())
    if (!response?.hasError) {
      setLoading(false)
      setQuoteData(response?.quote)
      // dispatch(setQuoteResult(response?.quote))
      setSuccessModal(true)

      // history.push(`/custom/quote-reference`)
    } else {
      setLoading(false)
      setErrorModal(true)

      // Swal.fire({
      //   icon: `error`,
      //   title: `Oops...`,
      //   text: `Something went wrong!`,
      // }).then(() => history.push(`/`))
    }
  }

  const createProspect = async (data) => {
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/add`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          ip: currentCustomer?.ip,
          source: `WEB`,
        }),
      }).then((res) => res.json())

      if (!response?.hasError) {
        dispatch(setLeadId(response?.data?.leadId))
      } else {
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      }
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError &&
      !errors.lastNameError &&
      !errors.dobError &&
      !errors.phoneError
    ) {
      // handleAppendData()
      handlePayload()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className="flex sm:justify-center sm:items-center">
      <div className="bg-form-gray sm:shadow-lg rounded-md py-2  sm:py-6 sm:px-0">
        <img
          src={contactCard}
          alt="Image Not Found"
          className="mx-auto h-16 w-16"
        />

        <div className="flex-1 ">
          <h2 className="sm:text-md text-center w-full mx-auto pb-2">
            {t(`customForm.contactInfo`)}
          </h2>
        </div>
        <div className="flex flex-col w-full mx-auto sm:w-auto sm:items-center sm:gap-6 pt-2 sm:pt-4 px-4">
          <div className="flex-1 relative w-350">
            <InputField
              title={t(`customLife.firstName`)}
              type="text"
              name="firstName"
              errorMessage={errors?.firstNameError}
              setValue={(e) => {
                handleCustomerChange(`firstName`, e.target.value)
              }}
              placeholder={t(`customLife.firstName`)}
              value={customer?.firstName}
            />
          </div>
          <div className="flex-1 relative w-350">
            <InputField
              title={t(`customLife.lastName`)}
              type="text"
              errorMessage={errors?.lastNameError}
              setValue={(e) => {
                handleCustomerChange(`lastName`, e.target.value)
              }}
              placeholder={t(`customLife.lastName`)}
              value={customer?.lastName}
            />
          </div>
          <div className="flex-1 relative w-350">
            <DatePickerField
              title={t(`customLife.DateOfBirth`)}
              name="dob"
              placeholder={t(`customLife.DateOfBirth`)}
              errorMessage={errors.dobError}
              setValue={(date) => {
                handleCustomerChange(`dob`, date)
              }}
              value={customer?.dob}
              maxDate={new Date(moment().subtract(16, `years`))}
            />
          </div>
          <div className="flex-1 relative w-350">
            <MaskField
              title={t(`customLife.preferredNumber`)}
              name="phone"
              errorMessage={errors?.phoneError}
              setValue={(e) => {
                handleCustomerChange(`phone`, e.target.value)
                // handlePhoneValidation(e.target.value)
              }}
              placeholder={t(`customLife.preferredNumber`)}
              value={customer?.phone}
            />
          </div>
        </div>

        <div className="flex pt-6 px-2 sm:pb-0 gap-4">
          <div className="flex-1 pb-8">
            <SubmitButton
              onClick={() => dispatch(setActiveCustomTab(activeTab - 1))}
            >
              <span className="pr-3">
                <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
              </span>
              {t(`Date-of-birth.previous`)}
            </SubmitButton>
          </div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>

          <div className="flex-1  pb-8">
            <SubmitButton
              onClick={() => {
                validateForm()
              }}
            >
              {/* {t(`PRIMARY-NAME.next`)} */}
              {t(`customLife.submit`)}
              <span className="pl-3">{loading && <Loader />}</span>
              {/* <span className="pl-3">
                {!loading ? (
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                ) : (
                  <Loader />
                )}
              </span> */}
            </SubmitButton>
          </div>
        </div>
      </div>

      {errorModal && (
        <div className="absolute flex justify-center text-center mx-auto">
          <div className="overflow-y-auto overflow-x-hidden mx-auto ml-10 mt-44 md:ml-0 md:mt-0   z-50 md:w-full  md:inset-0 h-modal md:h-full">
            <div className="relative p-4 md:w-full md:max-w-xs mx-auto h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Error
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setErrorModal(false)
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.errorMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <button
                    className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl"
                    onClick={() => {
                      setErrorModal(false)
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}

      {successModal && (
        <div className="absolute flex text-center mx-auto ">
          <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full mt-44 md:mt-0 md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full md:max-w-xs h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Quote # [{quoteData?.quoteId}]
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setSuccessModal(false)
                      createProspect()
                      dispatch(setActiveCustomerTab(1))
                      dispatch(setActiveCustomTab(1))
                      handleCustomerChange(`doYouSmoke`, ``)
                      handleCustomerChange(
                        `dob`,
                        new Date(moment().subtract(16, `years`)),
                      )
                      handleCustomerChange(`firstName`, ``)
                      handleCustomerChange(`lastName`, ``)
                      handleCustomerChange(`phone`, ``)
                      history.push(`/`)
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.successMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <p className="text-left pt-0.5 text-sm">
                    {t(`getQuote.buttonSection`)}
                  </p>
                  <button className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl">
                    <a href="tel:(800) 639-3939">
                      {t(`getQuote.contactAgent`)}
                    </a>
                  </button>
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => handleChatModel(true)}> {t(`customQuote.chat`)}</button> */}
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => {
                    setOpenModal(false)
                    setSuccessModal(false)
                    }}> Ok</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </div>
  )
}
