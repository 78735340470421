/* eslint-disable react/prop-types */
import React from 'react'
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export const MaskField = ({
  type,
  value,
  name,
  title,
  id,
  disabled,
  placeholder,
  errorMessage,
  setValue,
}) => {
  return (
    <>
      <label className="text-gray-700 font-medium text-sm">
        {title}
        {errorMessage && <span className="text-red-500 required-dot">*</span>}
      </label>
      <InputMask
        type={type}
        mask="+19999999999"
        maskChar={null}
        className="rounded-md border-transparent 
        flex-1 appearance-none border border-gray-300
        w-full mt-1 py-1.5 px-4 bg-white text-gray-700
      placeholder-gray-400 shadow-sm text-base
       focus:outline-none focus:ring-1
        focus:ring-red-300 focus:border-transparent"
        name={name}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e)}
      />
      {errorMessage && (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="absolute text-red-500 right-2 mt-4"
          />
          <p className=" text-sm text-red-500 mb-1">{errorMessage}</p>
        </>
      )}
    </>
  )
}
