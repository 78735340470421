import { SubmitButton } from 'App/Styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const GetCustomQuote = () => {
  const { t } = useTranslation()
  const [openModel, setOpenModal] = useState(false)
  const { quoteIDResult } = useSelector(({ custom }) => custom)

  const handleChatModel = () => {
    window?._chatlio.show({
      expanded: true,
    })
  }

  return (
    <>
      <div className="background-gradient w-screen h-80 sm:72 flex flex-col justify-evenly items-center">
        <div>
          <p className="text-white text-3xl text-center">
            {t(`Quote.title`)}: {quoteIDResult.quoteId}
          </p>
          <p className="text-white text-2xl text-center mt-2">
            {t(`customQuote.title`)}
          </p>
        </div>
        {/* <div className='flex space'>
          {quoteIDResult?.offers?.map((offer, index) => (
                <div
                  className="w-72 max-w-96 py-1 my-auto h-32 text-center border px-4 rounded-md shadow-md bg-gray-50"
                  key={index}
                >
                  <p className="text-2xl text-center h-2/3 py-6">
                    ${offer?.price}
                    <span className="text-sm">/month</span>
                  </p>
                </div>
                //
              ))}

        </div> */}
        <div className="flex flex-col sm:flex-row w-1/2 gap-2 mx-auto items-center">
          <SubmitButton onClick={() => handleChatModel()}>
            {t(`customQuote.chat`)}
          </SubmitButton>
          <SubmitButton onClick={() => setOpenModal(true)}>
            {t(`customQuote.call`)}
          </SubmitButton>
        </div>
      </div>
      {openModel && (
        <div className="flex justify-center mx-auto -mt-48">
          <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full max-w-xs h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600">
                  <p className="px-4 text-lg">Agent contact number</p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setOpenModal(false)}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium  text-xl text-blue-400">
                    <a href="tel:(800) 639-3939">(800) 639-3939</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </>
  )
}
