/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  setActiveCustomerTab,
  setActiveCustomTab,
  setCurrentCustom,
  setLeadId,
  setOpenModalCustom,
  setQuoteResult,
} from 'App/Redux/actions'
import { MaskField } from 'App/Components/Common/MaskField'

import { getOtp } from 'App/Services/OTP/getOtp'
import { InputField } from 'App/Components/Common/InputField'
import { Radio } from 'App/Components/Common/RadioButton'
import { regEx } from 'App/Services'
import { Contact, contactCard } from 'App/Assets'
import { useTranslation } from 'react-i18next'
import { requestQuote } from 'App/Services/RequestQoute'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { PQA_BACKEND_API } from 'App/Config'

export const PetFormPersonalInfo = ({ activeTab }) => {
  const { customer, address } = useSelector(({ custom }) => custom)
  const {
    leadId,
    prospectType,
    customer: currentCustomer,
  } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [quoteData, setQuoteData] = useState(``)

  const [errors, setErrors] = useState({
    firstNameError: ``,
    lastNameError: ``,
    // emailError: ``,
    phoneError: ``,
    formSubmit: false,
  })
  const history = useHistory()

  const handleErrors = (name, value) => {
    setErrors((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    // if (!customer.email.length) {
    //   handleErrors(`emailError`, `Enter Email`)
    // } else if (customer.email.length) {
    //   if (!regEx.test(customer.email.toLowerCase())) {
    //     handleErrors(`emailError`, `Enter Valid Email`)
    //   } else {
    //     handleErrors(`emailError`, ``)
    //   }
    // } else {
    //   handleErrors(`emailError`, ``)
    // }

    if (!customer.phone.length || customer.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else handleErrors(`phoneError`, ``)

    if (!customer.firstName.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else handleErrors(`firstNameError`, ``)

    if (!customer.lastName.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else handleErrors(`lastNameError`, ``)

    handleErrors(`formSubmit`, true)
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const prepayload = () => {
    let data = {
      quoteData: {
        customer: {
          age: customer?.age,
          breed: customer?.breed,
          lastTimeVisitedVetDays: customer?.lastTimeVisitedVet.value,
          animal: customer.animal.value,
          customerData: {
            firstName: customer?.firstName,
            lastName: customer?.lastName,
            phone: customer?.phone,
          },
        },
        quoteSource: `WEB`,
        quoteType: prospectType,
        ip: currentCustomer?.ip,
        prospectId: leadId,
      },
    }

    if (customer?.email.length) {
      data.quoteData.customer.customerData.email = customer?.email
    }

    return data
  }

  const handlePayload = async () => {
    const response = await requestQuote(prepayload())
    if (!response.hasError) {
      setLoading(false)
      // dispatch(setQuoteResult(response?.quote))
      setQuoteData(response?.quote)
      setSuccessModal(true)
      // history.push(`/custom/quote-reference`)
    } else {
      setLoading(false)
      setErrorModal(true)

      // Swal.fire({
      //   icon: `error`,
      //   title: `Oops...`,
      //   text: `Something went wrong!`,
      // }).then(() => history.push(`/`))
    }
  }

  const createProspect = async (data) => {
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/add`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          ip: currentCustomer?.ip,
          source: `WEB`,
        }),
      }).then((res) => res.json())

      if (!response?.hasError) {
        dispatch(setLeadId(response?.data?.leadId))
      } else {
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      }
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      // !errors.emailError &&
      !errors.phoneError &&
      !errors.firstNameError &&
      !errors.lastNameError
    ) {
      setLoading(true)
      handlePayload()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className="flex sm:justify-center sm:items-center my-auto">
      <div className="bg-form-gray sm:shadow-lg rounded-md pt-6 px-10">
        <img
          src={contactCard}
          alt="Image Not Found"
          className="mx-auto h-14 w-16"
        />
        <div className="flex-1">
          <h2 className="sm:text-md text-center w-full mx-auto py-2 sm:py-4">
            {t(`customPet.contactInfo`)}
          </h2>
        </div>

        <div className="flex flex-col w-full mx-auto sm:w-auto sm:items-center sm:gap-6 pt-2 sm:pt-4 md:px-2">
          <div className="flex-1 relative">
            <div className="text-gray-700 font-medium text-sm">
              {t(`customPet.firstName`)}
            </div>
            <InputField
              type="text"
              name="firstName"
              errorMessage={errors?.firstNameError}
              setValue={(e) => {
                handleCustomerChange(`firstName`, e.target.value)
              }}
              placeholder={t(`customPet.firstName`)}
              value={customer?.firstName}
            />
          </div>
          <div className="flex-1 relative">
            <div className="text-gray-700 font-medium text-sm">
              {t(`customPet.lastName`)}
            </div>
            <InputField
              type="text"
              errorMessage={errors?.lastNameError}
              setValue={(e) => {
                handleCustomerChange(`lastName`, e.target.value)
              }}
              placeholder={t(`customPet.lastName`)}
              value={customer?.lastName}
            />
          </div>
          {/* <div className="flex-1 relative">
            <div className='text-gray-700 font-medium text-sm'>
              {t(`ContactInfo.email`)}
            </div>
            <InputField
              type="email"
              name="email"
              errorMessage={errors?.emailError}
              setValue={(e) => handleCustomerChange(`email`, e.target.value)}
              placeholder={t(`ContactInfo.email`)}
              value={customer?.email}
            />
          </div> */}
          <div className="flex-1 relative">
            <div className="text-gray-700 font-medium text-sm">
              {t(`customPet.preferredNumber`)}
            </div>
            <MaskField
              name="phone"
              errorMessage={errors?.phoneError || errors.phoneVerifiedError}
              setValue={(e) => {
                handleCustomerChange(`phone`, e.target.value)
                // if (!customer?.phone && customer?.phone !== `+1`)
                //   handlePhoneValidation(e.target.value)
              }}
              placeholder={t(`customPet.preferredNumber`)}
              value={customer?.phone}
            />
          </div>
        </div>

        <div className="flex pt-6 px-2 sm:pb-0 gap-4">
          <div className="flex-1">
            <SubmitButton
              onClick={() => dispatch(setActiveCustomTab(activeTab - 1))}
            >
              <span className="pr-3">
                <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
              </span>
              {t(`Address.previous`)}
            </SubmitButton>
          </div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>

          <div className="flex-1 pb-8">
            <SubmitButton
              onClick={() => {
                validateForm()
              }}
            >
              {/* {t(`Referring.getQuote`)} */}
              {t(`customPet.submit`)}
              <span className="pl-3">{loading && <Loader />}</span>
            </SubmitButton>
          </div>
        </div>
      </div>

      {errorModal && (
        <div className="absolute flex text-center mx-auto">
          <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full mt-44 md:mt-0 md:inset-0 h-modal md:h-full">
            <div className="md:relative p-4 w-full md:max-w-xs h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="md:relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Error
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setErrorModal(false)
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.errorMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <button
                    className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl"
                    onClick={() => {
                      setErrorModal(false)
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}

      {successModal && (
        <div className="absolute flex text-center mx-auto">
          <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full mt-44 md:mt-0 md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full max-w-xs h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Quote # [{quoteData?.quoteId}]
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setSuccessModal(false)
                      createProspect()
                      dispatch(setActiveCustomerTab(1))
                      dispatch(setActiveCustomTab(1))
                      handleCustomerChange(`breed`, ``)
                      handleCustomerChange(`age`, ``)
                      handleCustomerChange(`lastTimeVisitedVet`, ``)
                      handleCustomerChange(`firstName`, ``)
                      handleCustomerChange(`lastName`, ``)
                      handleCustomerChange(`phone`, ``)
                      history.push(`/`)
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.successMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <p className="text-left pt-0.5 text-sm">
                    {t(`getQuote.buttonSection`)}
                  </p>
                  <button className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl">
                    <a href="tel:(800) 639-3939">
                      {t(`getQuote.contactAgent`)}
                    </a>
                  </button>
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => handleChatModel(true)}> {t(`customQuote.chat`)}</button> */}
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => {
                    setOpenModal(false)
                    setSuccessModal(false)
                    }}> Ok</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </div>
  )
}
