import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  openModal: false,
  activeTabCustom: 1,
  phone: ``,
  customer: {
    email: ``,
    phone: ``,
    channel: ``,
    firstName: ``,
    lastName: ``,
    doYouSmoke: ``,
    age: ``,
    breed: ``,
    lastTimeVisitedVet: ``,
    animal: ``,
    dob: new Date(moment().subtract(16, `years`)),
  },
  address: {},
  quoteIDResult: ``,
}

const customSlice = createSlice({
  name: `custom`,

  initialState,
  reducers: {
    setActiveCustomTab: (state, { payload }) => {
      state.activeTabCustom = payload
    },
    setQuoteResult: (state, { payload }) => {
      state.quoteIDResult = payload
    },
    setOpenModalCustom: (state, { payload }) => {
      state.openModal = payload
    },
    setCurrentPhone: (state, { payload }) => {
      state.phone = payload
    },
    setCurrentCustom: (state, { payload }) => {
      state.customer[payload.name] = payload.value
    },
    setCurrentCustomAddress: (state, { payload }) => {
      state.address = payload
    },
  },
})

export const {
  setActiveCustomTab,
  setCurrentCustom,
  setOpenModalCustom,
  setCustomPhone,
  setCurrentCustomAddress,
  setQuoteResult,
} = customSlice.actions

export const custom = customSlice.reducer
