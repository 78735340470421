import { createSlice } from '@reduxjs/toolkit'
// import moment from 'moment'

const initialState = {
  prospect: ``,
}

const globalSlice = createSlice({
  name: `global`,

  initialState,
  reducers: {
    setGlobalProspect: (state, { payload }) => {
      state.prospect = payload
    },
  },
})

export const { setGlobalProspect } = globalSlice.actions

export const global = globalSlice.reducer
