/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ValidateZip } from 'App/Services/Validate/validateZip'
import { SubmitButton } from 'App/Styled'
import { setActiveCustomerTab, setCurrentCustomer } from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { regEx } from 'App/Services'
// import { Radio } from 'App/Components/Common/RadioButton'
import { InputField } from 'App/Components/Common/InputField'
import Swal from 'sweetalert2'
import { Spinner } from 'App/Components/Common/Spinner'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Name, zipCode } from 'App/Assets'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import moment from 'moment'
import { appendData } from 'App/Services/Append'

export const GetInitialInfo = ({ activeTab }) => {
  const { customer, leadId } = useSelector(({ customer }) => customer)

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({
    zipError: ``,
    dobError: ``,
    formSubmit: false,
  })

  const [address, setAddress] = useState({
    city: ``,
    zip: ``,
    state: ``,
  })

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustomer({
        name,
        value,
      }),
    )
  }

  const handleErrors = (name, value) => {
    setErrors((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        zip: address.zip,
        dob: customer?.dob,
      },
      leadId,
    )
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setCurrentCustomer({ name: `address`, value: address }))
      dispatch(setActiveCustomerTab(activeTab + 1))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  const validateForm = () => {
    if (!customer.firstName.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else handleErrors(`firstNameError`, ``)
    if (!customer.lastName.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else handleErrors(`firstNameError`, ``)
    if (!customer.phone.length || customer.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else handleErrors(`phoneError`, ``)

    if (customer.email?.length) {
      if (!regEx.test(customer.email?.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
    } else handleErrors(`emailError`, `Enter  Email`)
    if (!address?.zip?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else handleErrors(`zipError`, ``)

    handleErrors(`formSubmit`, true)
  }

  const handleAddressInput = useDebouncedCallback(async () => {
    const response = await ValidateZip(address.zip)
    if (!response?.hasError) {
      setAddress((prevState) => ({
        ...prevState,
        [`city`]: response?.result?.city,
        [`state`]: response?.result?.state,
      }))
      handleErrors(`formSubmit`, false)
      handleErrors(`zipError`, ``)
    } else {
      handleErrors(`formSubmit`, false)
      handleErrors(`zipError`, `${t(`Address.caZip`)}`)
    }
  }, 300)

  useEffect(() => {
    if (address.zip.length) {
      handleAddressInput()
    }
  }, [address.zip])

  useEffect(() => {
    if (errors.formSubmit && !errors.zipError?.length) {
      handleAppendData()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <>
      <Spinner loading={loading} />

      <div
        className={`flex sm:justify-center sm:items-center mt-2 ${
          loading && `opacity-30 pointer-events-none`
        }`}
      >
        <div className=" bg-form-gray sm:shadow-lg rounded-md sm:py-2 px-6 sm:px-10">
          <div className="flex-1 ">
            {/* <h2 className="text-custom-red sm:text-md text-center w-full mx-auto py-2 sm:py-4">
              Information
            </h2> */}
          </div>

          <img
            src={zipCode}
            alt="Image Not Found"
            className="mx-auto h-14 w-16"
          />

          <div className="flex-1 mt-3">
            <h2 className="sm:text-md text-center w-full mx-auto">
              {t(`customLife.personalInfo`)}
            </h2>
          </div>

          <div className="flex flex-col w-full mx-auto sm:flex-row sm:w-auto sm:items-center sm:gap-6 sm:pt-4 -mt-2">
            <div>
              <div className="flex-1 relative ">
                <InputField
                  title={t(`Address.zipCode`)}
                  type="text"
                  errorMessage={errors.zipError}
                  setValue={(e) => {
                    setAddress((prevState) => ({
                      ...prevState,
                      [`zip`]: e.target.value,
                    }))
                  }}
                  placeholder={t(`Address.zipCode`)}
                  value={address?.zip}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title={t(`Date-of-birth.DateOfBirth`)}
                  name="dob"
                  placeholder={t(`Date-of-birth.DateOfBirth`)}
                  errorMessage={errors.dobError}
                  setValue={(date) => {
                    handleCustomerChange(`dob`, date)
                  }}
                  value={customer?.dob}
                  maxDate={new Date(moment().subtract(16, `years`))}
                />
              </div>
            </div>
          </div>

          <div className="flex py-4 sm:pb-0 gap-4">
            <div className="flex-1"></div>
            <div className="flex-1"></div>
            <div className="flex-1"></div>

            <div className="flex-1">
              <SubmitButton
                onClick={() => {
                  validateForm()
                }}
                disabled={errors.zipError?.length}
              >
                {t(`Year.next`)}
                <span className="pl-3">
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                </span>
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
