/* eslint-disable react/prop-types */
import { ButtonTransparent } from 'App/Styled'
import React, { useState } from 'react'
import { useEffect } from 'react';

export const Radio = ({
  label,
  name,
  id,
  value,
  errorMessage,
  setValue,
  button,
  buttonLabel,
  selected
}) => {
  const [checked, setChecked] = useState(false)


  useEffect(() =>{
    setChecked(selected)

    return () =>{
      setChecked(false)
    }
  },[selected,checked])

  return (
    <>
      <label
        id={id}
        className="inline-flex items-center mt-2 cursor-pointer font-light"
      >
        <input
          type="radio"
          className={`cursor-pointer mb-1  ${
            button ? `hidden-radio color-radio` : `block-radio`
          }`}
          name={name}
          id={id}
          value={value}
          onClick={(e) => {
            setChecked(e.target.checked)
            setValue(e)
          }}
        />
        {button && (
          <ButtonTransparent
            className={
              (checked) &&
              (value === checked) &&
              `bg-red-500 text-white`
            }
          >
            {buttonLabel}
          </ButtonTransparent>
        )}
        <span className={`${button && `hidden`}pl-3 pb-1`}>
          {!button && label}
          {errorMessage && (
            <span className="text-red-500 text-lg pl-2 required-dot">*</span>
          )}
        </span>
      </label>
    </>
  )
}
