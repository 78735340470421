/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Logo, mexicoFlag, UsaFlag } from 'App/Assets'
import {
  setActiveCustomTab,
  setGlobalProspect,
  setLeadId,
  setProspectType,
} from 'App/Redux/actions'
import i18next from 'i18next'

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ReactSelectField } from '../Common/ReactSelect'
import { Footer } from '../Footer'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { PQA_BACKEND_API } from 'App/Config'
import { customer, setActiveCustomerTab } from 'App/Redux/slices/customer'

export const Layout = ({ children }) => {
  const { activeTab, customer } = useSelector(({ customer }) => customer)
  const search = useLocation().search
  const dispatch = useDispatch()
  const currentKey = new URLSearchParams(search).get(`key`)
  const currentLang = new URLSearchParams(search).get(`lang`)
  const { t } = useTranslation()

  const searchURL = window.location.search

  console.log(` -- PQA_BACKEND_API -- `, PQA_BACKEND_API)

  const history = useHistory()
  const loadLanguages = () => {
    const options = {
      options: [
        { label: `English`, value: `en` },
        {
          label: `Spanish`,
          value: `sp`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  useEffect(() => {
    if (searchURL) {
      if (currentKey) {
        if (currentKey === `auto`) {
          dispatch(setProspectType(currentKey?.toUpperCase()))
          history.push(
            `/${currentKey}/?key=${currentKey}${
              currentLang ? `&lang=${currentLang}` : ``
            }`,
          )
        } else {
          dispatch(setProspectType(currentKey?.toUpperCase()))
          dispatch(setGlobalProspect(currentKey?.toUpperCase()))
          history.push(
            `/custom/?key=${currentKey}${
              currentLang ? `&lang=${currentLang}` : ``
            }`,
          )
        }
      }
      if (currentLang) {
        i18next.changeLanguage(currentLang)
      }
    }
  }, [searchURL])

  useEffect(() => {
    if (!searchURL) {
      i18next.changeLanguage(`en`)
    }
  }, [searchURL])

  const createProspect = async (data) => {
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/add`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          ip: customer.ip,
          source: `WEB`,
        }),
      }).then((res) => res.json())

      if (!response?.hasError) {
        dispatch(setLeadId(response?.data?.leadId))
      } else {
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      }
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  return (
    <div className="h-full">
      <div className="md:flex-0 md:w-full  md:h-1/4 custom-header z-40">
        {/* <div className="flex justify-end w-full px-48 h-6 z-50 bg-white px-56">
          <div className="flex pb-2 pt-4">
            <div className="flex">
              <div className="my-auto font-semibold text-xl">ENG</div>
              <img
                className="sm:px-1 mr-2 w-16 cursor-pointer"
                src={UsaFlag}
                alt="usa flag"
                width="200"
                height="50"
                onClick={() => i18next.changeLanguage(`en`)}
              />
            </div>
            <div className="flex">
              <div className="my-auto font-semibold text-xl">ESP</div>
              <img
                className="sm:px-1 w-16 cursor-pointer"
                src={mexicoFlag}
                alt="mexico flag"
                onClick={() => i18next.changeLanguage(`sp`)}
              />
            </div>
          </div>
        </div> */}
        <div className="flex justify-between items-center h-90 bg-black sm:h-8 sticky top-0 z-40 px-4 sm:px-40">
          <div className="flex h:10 pt-3 sm:h-14 md:h-20 justify-start gap-x-44 sm:justify-between items-center">
            <img
              className="sm:px-4 w-28 sm:w-40 md:w-60 cursor-pointer"
              src={Logo}
              alt="Veronicas Logo"
              onClick={() => {
                history.push(`/`)
                window.location.reload()
                dispatch(setActiveCustomerTab(1))
                dispatch(setActiveCustomTab(1))
                createProspect()
              }}
            />
            <div className="md:px-12">
              {/* <div className='flex w-48'>
                <div className='flex'>
                  <img
                  className="sm:px-4 sm:w-40 md:w-60 cursor-pointer"
                  src={UsaFlag}
                  alt="usa flag"
                  width="200"
                  height="50"
                  onClick={() => i18next.changeLanguage(`en`)}
                  />
                </div>
                <div className='flex'>
                  <img
                  className="sm:px-4 sm:w-40 md:w-60 cursor-pointer"
                  src={mexicoFlag}
                  alt="mexico flag"
                  onClick={() => i18next.changeLanguage(`sp`)}
                  />
                </div>
              </div> */}
              {/* <ReactSelectField
                placeholder="Language"
                name="year"
                loadOptions={loadLanguages}
                setValue={(item) => {
                  i18next.changeLanguage(item.value)
                }}
                isMulti={false}
              /> */}
            </div>
          </div>
          <div className="flex pb-2 pt-4 h-16 gap-x-2 sm:gap-x-0 text-white">
            <div className="flex items-center gap-x-1 sm:gap-x-0">
              <div className="my-auto font-semibold text-xs md:text-xl">
                ENG
              </div>
              <img
                className="sm:px-1 mr-2 w-8 h-6 md:h-auto md:w-16 cursor-pointer"
                src={UsaFlag}
                alt="usa flag"
                // width="200"
                // height="50"
                onClick={() => i18next.changeLanguage(`en`)}
              />
            </div>
            <div className="flex items-center gap-x-1 sm:gap-x-0">
              <div className="my-auto font-semibold text-xs sm:text-xl">
                ESP
              </div>
              <img
                className="sm:px-1 mr-2 w-8 h-6 md:h-auto md:w-16 cursor-pointer"
                src={mexicoFlag}
                alt="mexico flag"
                onClick={() => i18next.changeLanguage(`sp`)}
              />
            </div>
          </div>
        </div>
        {/* Icon and Slect Language */}
      </div>

      <div className="md:h-3/4 bg-white grid mt-24 sm:mt-10 items-center justify-center custom-margin">
        {children}
        {/* <Footer activeTab={activeTab} /> */}
      </div>
    </div>
  )
}
