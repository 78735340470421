import { PQA_BACKEND_API } from 'App/Config'
import Swal from 'sweetalert2'

export const requestQuote = async (data) => {
  try {
    const response = await fetch(`${PQA_BACKEND_API}/api/quotes/request`, {
      method: `POST`,
      headers: {
        Accept: `application/json, text/plain, */*`,
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        ...data,
      }),
    }).then((res) => res.json())
    // if (response?.hasError) {
    //   Swal.fire({
    //     icon: `error`,
    //     title: `Oops...`,
    //     text: `Something went wrong!`,
    //   }).then(() => (window.location = `/`))
    // } else {
    //   Swal.fire({
    //     icon: `success`,
    //     title: `Your Lead Id: ${response?.quote?.quoteId}`,
    //     text: `Subscribe Successfully`,
    //   }).then(() => (window.location = `/`))
    // }
    return response
  } catch (err) {
    Swal.fire({
      icon: `error`,
      title: `Oops...`,
      text: `Something went wrong!`,
    })
  }
}
