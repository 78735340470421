/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import {
  setActiveCustomerTab,
  setActiveCustomTab,
  setCurrentCustom,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { appendData } from 'App/Services/Append'
import { petPaw } from 'App/Assets'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { useTranslation } from 'react-i18next'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'

export const PetInfo = ({ activeTab }) => {
  const { customer } = useSelector(({ custom }) => custom)
  const { leadId } = useSelector(({ customer }) => customer)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [errors, setErrors] = useState({
    ageError: ``,
    breedError: ``,
    lastTimeVisitedVetError: ``,
    formSubmit: false,
  })

  const dispatch = useDispatch()

  const validateForm = () => {
    dispatch(setActiveCustomerTab(activeTab + 1))
    if (!customer?.age?.length) {
      handleErrors(`ageError`, `Enter Age`)
    } else if (customer?.age < 0) {
      handleErrors(`ageError`, `Enter Valid Age`)
    } else {
      handleErrors(`ageError`, ``)
    }

    if (!customer?.breed?.length) {
      handleErrors(`breedError`, `Enter Breed`)
    } else {
      handleErrors(`breedError`, ``)
    }

    if (!customer?.lastTimeVisitedVet?.value?.length) {
      handleErrors(`lastTimeVisitedVetError`, `Enter Value`)
    } else if (customer?.lastTimeVisitedVet < 0) {
      handleErrors(`lastTimeVisitedVetError`, `Enter Valid Number`)
    } else {
      handleErrors(`lastTimeVisitedVetError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        age: customer?.age,
        breed: customer?.breed,
        lastTimeVisitedVet: customer?.lastTimeVisitedVet?.value,
        animal: customer?.animal?.value,
      },
      leadId,
    )
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setActiveCustomTab(activeTab + 1))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.ageError &&
      !errors.breedError &&
      !errors.lastTimeVisitedVetError
    ) {
      handleAppendData()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const loadVetOptions = async () => {
    return {
      options: [
        { value: `30`, label: `1 WEEK AGO-3 WEEKS AGO` },
        { value: `150`, label: `1 MONTH-5 MONTHS` },
        { value: `360`, label: `6 MONTHS-1 YEAR` },
        { value: `400`, label: `+1 YEAR` },
      ],
      hasMore: false,
    }
  }

  const loadAnimalOptions = async () => {
    return {
      options: [
        { value: `Cat`, label: `Cat` },
        { value: `Dog`, label: `Dog` },
      ],
      hasMore: false,
    }
  }

  return (
    <div className="flex sm:justify-center sm:items-center">
      <div className="bg-form-gray sm:shadow-lg rounded-md py-2 md:mx-4 md:px-8 sm:py-6 sm:px-0">
        <img src={petPaw} alt="Image Not Found" className="mx-auto h-14 w-16" />

        <div className="flex-1 ">
          <h2 className="sm:text-md text-center w-full mx-auto pb-2">
            {t(`customPet.petInfo`)}
          </h2>
          {/* <p className="text-center sm:w-2/3 mx-auto">
            {t(`PRIMARY-NAME.subHeading`)}
          </p> */}
        </div>
        <div className="flex flex-col w-full mx-auto sm:w-auto sm:items-center sm:gap-6 pt-2 sm:pt-4 px-4 md:px-12">
          <div className="flex-1 relative w-350">
            <ReactSelectField
              title={t(`customPet.selectOne`)}
              name="make"
              placeholder={t(`customPet.selectOne`)}
              loadOptions={loadAnimalOptions}
              setValue={(item) => {
                handleCustomerChange(`animal`, item)
              }}
              isSearchable={false}
              value={customer?.animal}
              isMulti={false}
            />
          </div>
          <div className="flex-1 relative w-350">
            <div className="text-gray-700 font-medium text-sm">
              {t(`customPet.breed`)}
            </div>
            <InputField
              className="w-350"
              type="text"
              errorMessage={errors?.breedError}
              setValue={(e) => {
                handleCustomerChange(`breed`, e.target.value)
              }}
              placeholder={t(`customPet.breed`)}
              value={customer?.breed}
            />
          </div>

          <div className="flex-1 relative w-350">
            <div className="text-gray-700 font-medium text-sm">
              {t(`customPet.age`)}
            </div>
            <InputField
              className="w-350"
              type="number"
              name="age"
              errorMessage={errors?.ageError}
              setValue={(e) => {
                handleCustomerChange(`age`, e.target.value)
              }}
              placeholder={t(`customPet.age`)}
              value={customer?.age}
            />
          </div>

          <div className="flex-1 relative w-350">
            <ReactSelectField
              title={t(`customPet.lastTimeVisitedVetInDays`)}
              name="make"
              placeholder={t(`customPet.lastTimeVisitedVetInDays`)}
              loadOptions={loadVetOptions}
              setValue={(item) => {
                handleCustomerChange(`lastTimeVisitedVet`, item)
              }}
              isSearchable={false}
              value={customer?.lastTimeVisitedVet}
              isMulti={false}
            />
          </div>
        </div>

        <div className="flex pt-6 px-4 md:px-12 sm:pb-0 gap-4">
          <div className="flex-1 pb-8"></div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>

          <div className="flex-1 pb-8">
            <SubmitButton
              onClick={() => {
                validateForm()
              }}
            >
              {t(`customPet.claimQuote`)}
              <span className="pl-3">
                {!loading ? (
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                ) : (
                  <Loader />
                )}
              </span>
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  )
}
