/* eslint-disable react/prop-types */
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Spinner = ({ loading, size = `sm` }) => {
  return loading ? (
    <div className="relative">
      <div className="absolute top-80 bottom-80 w-full h-full flex justify-center items-center  z-40  p-4 text-2xl text-red-500">
        <FontAwesomeIcon size={size} icon={faSpinner} spin={true} />
      </div>
    </div>
  ) : (
    <></>
  )
}
