import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  addDriver: false,
  activeTabDriver: 1,
  currentDriver: {
    education: ``,
    isEmployed: ``,
    firstName: ``,
    lastName: ``,
    dob: new Date(moment().subtract(16, `years`)),
    gender: ``,
    relation: ``,
  },
  drivers: [],
}

const driverSlice = createSlice({
  name: `driver`,

  initialState,
  reducers: {
    setActiveDriverTab: (state, { payload }) => {
      state.activeTabDriver = payload
    },
    setCurrentDriver: (state, { payload }) => {
      state.currentDriver[payload.name] = payload.value
    },
    addDrivers: (state, { payload }) => {
      state.drivers.push(payload)
    },
    openAddDriver: (state, { payload }) => {
      state.addDriver = payload
    },
    setDriverInitialState: (state) => {
      state.currentDriver = {
        education: ``,
        isEmployed: ``,
        firstName: ``,
        lastName: ``,
        dob: new Date(moment().subtract(16, `years`)),
        gender: ``,
        relation: ``,
      }
    },
  },
})

export const {
  setCurrentDriver,
  openAddDriver,
  addDrivers,
  setActiveDriverTab,
  setDriverInitialState,
} = driverSlice.actions

export const driver = driverSlice.reducer
