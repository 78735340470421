import { PQA_BACKEND_API } from 'App/Config'
import Swal from 'sweetalert2'

export const appendData = async (data, id) => {
  try {
    const response = await fetch(`${PQA_BACKEND_API}/api/leads/${id}/append`, {
      method: `PATCH`,
      headers: {
        Accept: `application/json, text/plain, */*`,
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        ...data,
      }),
    }).then((res) => res.json())
    return response
  } catch (err) {
    Swal.fire({
      icon: `error`,
      title: `Oops...`,
      text: `Something went wrong!`,
    })
  }
}
