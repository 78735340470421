/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { SubmitButton } from 'App/Styled'
import { setCurrentCustomer } from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
// import { Radio } from 'App/Components/Common/RadioButton'
import { InputField } from 'App/Components/Common/InputField'
import { MaskField } from 'App/Components/Common/MaskField'
import { useTranslation } from 'react-i18next'
import { contactCard } from 'App/Assets'
import { appendData } from 'App/Services/Append'
import { Loader } from 'App/Components/Common/Loader'

export const FinalPersonalInfo = ({ closeModal, setErrorModal }) => {
  const { customer, leadId } = useSelector(({ customer }) => customer)

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({
    phoneError: ``,
    firstNameError: ``,
    lastNameError: ``,
    formSubmit: false,
  })

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustomer({
        name,
        value,
      }),
    )
  }

  const handleErrors = (name, value) => {
    setErrors((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    if (!customer.firstName.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else handleErrors(`firstNameError`, ``)
    if (!customer.lastName.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else handleErrors(`firstNameError`, ``)
    if (!customer.phone.length || customer.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else handleErrors(`phoneError`, ``)

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError.length &&
      !errors.lastNameError.length &&
      !errors.phoneError.length
    ) {
      handleAppendData()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  // const handleAddressInput = useDebouncedCallback(async () => {
  //     const response = await ValidateZip(address.zip)
  //     if (!response.hasError) {
  //       setAddress((prevState) => ({
  //         ...prevState,
  //         [`city`]: response?.result?.city,
  //         [`state`]: response?.result?.state,
  //       }))
  //       handleErrors(`formSubmit`, false)
  //       handleErrors(`zipError`, ``)
  //     } else {
  //       handleErrors(`formSubmit`, false)
  //       handleErrors(`zipError`, `${t(`Address.caZip`)}`)
  //     }
  // }, 300)

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        firstName: customer.firstName,
        lastName: customer?.lastName,
        phone: customer.phone,
      },
      leadId,
    )
    if (!response?.hasError) {
      handleCustomerChange(`firstName`, ``)
      handleCustomerChange(`lastName`, ``)
      handleCustomerChange(`phone`, ``)
      setLoading(false)
      closeModal()
    } else {
      setErrorModal()
      setLoading(false)
    }
  }

  return (
    <>
      {/* <Spinner loading={loading} /> */}

      <div className={`flex sm:justify-center sm:items-center mt-2`}>
        <div className="bg-form-gray sm:shadow-lg rounded-md  sm:py-2 px-6 sm:px-10">
          <div className="flex-1 ">
            {/* <h2 className="text-custom-red sm:text-md text-center w-full mx-auto py-2 sm:py-4">
              Information
            </h2> */}
          </div>

          <img
            src={contactCard}
            alt="Image Not Found"
            className="mx-auto h-14 w-16 mt-3"
          />

          <div className="flex-1 mt-3">
            <h2 className="sm:text-md text-center w-full mx-auto">
              {t(`customLife.contactInfo`)}
            </h2>
          </div>

          <div className="flex flex-col w-full mx-auto sm:flex-row sm:w-auto sm:items-center sm:gap-6 sm:pt-4 -mt-2">
            <div>
              <div className="flex-1 relative ">
                <InputField
                  title={t(`PRIMARY-NAME.firstName`)}
                  type="text"
                  name="firstName"
                  errorMessage={errors?.firstNameError}
                  setValue={(e) => {
                    handleCustomerChange(`firstName`, e.target.value)
                  }}
                  placeholder={t(`PRIMARY-NAME.firstName`) + `*`}
                  value={customer?.firstName}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  title={t(`PRIMARY-NAME.lastName`)}
                  type="text"
                  errorMessage={errors?.lastNameError}
                  setValue={(e) => {
                    handleCustomerChange(`lastName`, e.target.value)
                  }}
                  placeholder={t(`PRIMARY-NAME.lastName`) + `*`}
                  value={customer?.lastName}
                />
              </div>
              <div className="flex-1 relative">
                <MaskField
                  title={t(`customLife.preferredNumber`)}
                  name="phone"
                  errorMessage={errors?.phoneError}
                  setValue={(e) => {
                    handleCustomerChange(`phone`, e.target.value)
                    // handlePhoneValidation(e.target.value)
                  }}
                  placeholder={t(`customLife.preferredNumber`) + `*`}
                  value={customer?.phone}
                />
              </div>
            </div>
          </div>

          <div className="flex py-4 sm:pb-0 gap-4">
            <div className="flex-1">
              {/* <SubmitButton
                onClick={() => {
                  dispatch(setActiveCustomerTab(activeTab - 1))
                }}
              >
                <span className="pr-3">
                  <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
                </span>
                {t(`Education.previous`)}
              </SubmitButton> */}
            </div>
            <div className="flex-1"></div>
            <div className="flex-1"></div>

            <div className="flex-1">
              <SubmitButton
                onClick={() => {
                  // getReferrals(
                  //   {
                  //     firstName: ref?.firstName,
                  //     lastName: ref?.lastName,
                  //     middleName: ref?.middleName,
                  //     phone: ref?.phone,
                  //   },
                  //   leadId,
                  // )
                  // generateQuote(payload())
                  validateForm()
                }}
                disabled={
                  !customer.firstName.length ||
                  !customer.lastName.length ||
                  !customer.phone.length
                }
              >
                <span className="truncate">{t(`getQuote.submit`)}</span>
                {loading && (
                  <span className="pl-3">
                    <Loader />
                  </span>
                )}
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
