/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import {
  setActiveCustomerTab,
  setActiveCustomTab,
  setCurrentCustom,
  setCurrentCustomAddress,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { appendData } from 'App/Services/Append'
import { Name, zipCode } from 'App/Assets'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { useTranslation } from 'react-i18next'
import { ValidateZip } from 'App/Services/Validate/validateZip';
import { validateStreetAddress } from 'App/Services/Validate/validateAddtress';

export const CustomPersoanlInfo = ({ activeTab }) => {
  const { customer } = useSelector(({ custom }) => custom)
  const { leadId, prospectType } = useSelector(({ customer }) => customer)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: `abc`,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [errors, setErrors] = useState({
    // firstNameError: ``,
    // lastNameError: ``,
    zipError: ``,
    formSubmit: false,
  })
  
  const dispatch = useDispatch()

  const validateForm = () => {
    // if (!customer?.firstName?.length) {
    //   handleErrors(`firstNameError`, `Enter First Name`)
    // } else {
    //   handleErrors(`firstNameError`, ``)
    // }
    // if (!customer?.lastName?.length) {
    //   handleErrors(`lastNameError`, `Enter Last Name`)
    // } else {
    //   handleErrors(`lastNameError`, ``)
    // }

    dispatch(setActiveCustomerTab(activeTab + 1))
    if (!address?.zip?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAddressInput = async (name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value && name === `zip`) {
      const response = await ValidateZip(value)
      if (!response?.hasError) {
        const res = response?.result
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: res.city,
          [`state`]: res.state,
        }))
      }
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`zipError`, `Invalid Zip`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`zipError`, ``)
      }
    } else if (value && name === `streetNo`) {
      const response = await validateStreetAddress(
        `${value}, ${address.city} ${address.state} ${address.zip}`,
      )

      if (response?.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`streetNoError`, `Invalid Address`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`streetNoError`, ``)
      }
    }
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        // firstName: customer?.firstName,
        // lastName: customer?.lastName,
        prospectType,
        address: {
          street: address?.streetNo,
          city: address?.city,
          zip: address?.zip,
          state: address?.state,
        },
      },
      leadId,
    )
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setActiveCustomTab(activeTab + 1))
      dispatch(setCurrentCustomAddress(address))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  // useEffect(() => {
  //   if (searchURL) {
  //     dispatch(
  //       setProspectType(searchURL?.split(`&`)[0].split(`=`)[1].toUpperCase()),
  //     )
  //   }
  // }, [])

  useEffect(() => {
    if (errors.formSubmit && 
      // !errors.firstNameError && !errors.lastNameError &&  
      !errors.streetNoError &&
      !errors.cityError &&
      !errors.stateError &&
      !errors.zipError) {
      handleAppendData()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    setAddress((prevState) => ({
      ...prevState,
      [`city`]: ``,
      [`state`]: ``,
    }))
  }, [address.zip])

  return (
    <div className="flex sm:justify-center sm:items-center">
      <div className="bg-form-gray sm:shadow-lg rounded-md sm:py-2 px-6 sm:px-10">
        <img src={zipCode} alt="Image Not Found" className="mx-auto h-14 " />

        {/* <div className="flex-1 ">
          <h2 className="sm:text-md text-center w-full mx-auto pb-2">
          {t(`customForm.contactInfo`)}
          </h2>
        </div> */}
        <div className="flex flex-col w-full mx-auto sm:w-auto sm:items-center sm:gap-6 pt-2 sm:pt-4 px-2">
        <div className="flex-1 relative">
            <div className='text-gray-700 font-medium text-sm'>
              {t(`customForm.zipcode`)}
            </div>
            <InputField
                type="text"
                errorMessage={errors.zipError}
                setValue={(e) => handleAddressInput(`zip`, e.target.value)}
                placeholder={t(`Address.zipCode`)}
                value={address?.zip}
            />
          </div>
          {/* <div className="flex-1 relative">
            <div className='text-gray-700 font-medium text-sm'>
              {t(`PRIMARY-NAME.firstName`)}
            </div>
            <InputField
              type="text"
              name="firstName"
              errorMessage={errors?.firstNameError}
              setValue={(e) => {
                handleCustomerChange(`firstName`, e.target.value)
              }}
              placeholder={t(`PRIMARY-NAME.firstName`)}
              value={customer?.firstName}
            />
          </div> */}
          {/* <div className="flex-1 relative">
            <div className='text-gray-700 font-medium text-sm'>
            {t(`PRIMARY-NAME.lastName`)}
            </div>
            <InputField
              type="text"
              errorMessage={errors?.lastNameError}
              setValue={(e) => {
                handleCustomerChange(`lastName`, e.target.value)
              }}
              placeholder={t(`PRIMARY-NAME.lastName`)}
              value={customer?.lastName}
            />
          </div> */}
          
        </div>

        <div className="flex pt-6 px-2 sm:pb-0 gap-4">
          <div className="flex-1 pb-8"></div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>

          <div className="flex-1 pb-8">
            <SubmitButton
              onClick={() => {
                validateForm()
              }}
            >
              {t(`customForm.claimQuote`)}
              <span className="pl-3">
                {!loading ? (
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                ) : (
                  <Loader />
                )}
              </span>
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  )
}
